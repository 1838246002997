import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "компонент-create"
    }}>{`Компонент `}<inlineCode parentName="h1">{`<Create>`}</inlineCode></h1>
    <p>{`Компонент `}<inlineCode parentName="p">{`<Create>`}</inlineCode>{` нужен для отображения формы создания записы для ресурса`}</p>
    <p>{`Для создания записи компонент `}<inlineCode parentName="p">{`<Create>`}</inlineCode>{` вызывает `}<inlineCode parentName="p">{`create`}</inlineCode>{` метод из `}<inlineCode parentName="p">{`<dataProvider>`}</inlineCode>{` для текущего ресурса.`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(props) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onSuccessfulSubmit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() => void Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onFailedSubmit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`() => void Component Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`handleSubmit (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(values) => void`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create redirect="list">
  {(createProps) => (
    <SimpleForm
      {...createProps}
      initialValue={{
        title: '',
        body: '',
      }}
    >
      <Space direction="vertical">
        <TextInput label="Title" name="title" placeholder="Enter title" />
        <TextInput label="Body" name="body" placeholder="Enter body" />
      </Space>
    </SimpleForm>
  )}
</Create>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      